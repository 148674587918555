import ModalLayout from '../components/Layouts/ModalLayout'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import ListProjects from '../components/ListProjects'
import ListFeaturedProjects from '../components/ListFeaturedProjects'

import { capitalizeFirstLetter } from '../utils/helpers/utils'
import { black } from '../utils/constants/colors'
import { formatNodeProjectToProject } from '../utils/helpers/projects'
import { internationalRoutes, routeNames } from '../utils/helpers/routes'
import { ModalContentHeader } from '../components/ModalHeader'
import { getPrismicListDocument } from '../utils/helpers/transformers'
import { HistoryContext } from '../utils/hooks/History'
import {
  animateEntry,
  setEntry,
  setVisibleEntry,
} from '../utils/page-animations/industry'
import {
  animateModalHeaderContent,
  setModalHeaderContent,
} from '../utils/page-animations/common'
import useAnimationState from '../utils/hooks/useAnimationState'

import ScrollTrigger from 'gsap/ScrollTrigger'

const Industry = (props) => {
  const { data, pageContext } = props
  const { routeName, language } = pageContext

  // INTN
  const { t } = useTranslation(['industry'])
  const localizedRouteIndustries =
    internationalRoutes[routeNames.clients].langs[language]

  // ANIMATION
  const animationState = useAnimationState({ routeName })

  //CONTEXTS
  const { isLandingPage } = useContext(HistoryContext)

  // REF
  const $hasRunAnimation = useRef(false)
  const $hasRunModalAnimation = useRef(false)

  // DATA
  const needsLandingContent = useMemo(
    () => animationState.isEntryPage && isLandingPage,
    [animationState.isEntryPage, isLandingPage]
  )
  const industry = useMemo(() => data?.industry?.data, [data.industry])
  const industryName = industry ? industry.name.text.toLowerCase() : ''
  const projects = useMemo(
    () => getPrismicListDocument(industry, 'projects', 'project'),
    [industry]
  )
  const featuredProjects = projects.map((p) => ({ uid: p.uid, ...p.data }))

  const extProjects = useMemo(
    () =>
      data.extProjects.edges
        .filter(
          (projectData) =>
            projectData.node.data.industry.document?.uid === pageContext.uid
        )
        .reduce((acc, item) => {
          const project = formatNodeProjectToProject(item?.node)
          if (project) {
            acc.push(project)
          }
          return acc
        }, []),
    [data.extProjects.edges, pageContext]
  )

  const landingLink = {
    url: localizedRouteIndustries,
    label: t('industry:landing_link:label'),
  }

  // SEO
  const seoData = useMemo(() => {
    return {
      title: industry.seo_title
        ? industry.seo_title
        : t('industry:seo:title', { industry: industryName }),
      description: industry.seo_description
        ? industry.seo_description
        : industry.description.text,
      image: industry.seo_image.url ? industry.seo_image.url : null,
    }
  }, [industry, industryName, t])

  // HOOKS
  // Handle Hiding
  useEffect(() => {
    setEntry()
    ScrollTrigger.clearScrollMemory()
  }, [])

  useEffect(() => {
    if (!needsLandingContent) {
      return
    }
    setModalHeaderContent()
  }, [needsLandingContent])

  // Handle showing
  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunAnimation.current = true
      setVisibleEntry()
      return
    }
  }, [animationState.canStart, animationState.isReady])

  useEffect(() => {
    if ($hasRunModalAnimation.current || !needsLandingContent) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunModalAnimation.current = true
      setModalHeaderContent(true)
      return
    }
  }, [needsLandingContent, animationState.canStart, animationState.isReady])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }

    $hasRunAnimation.current = true
    const tls = animateEntry()

    return () => {
      tls.forEach((tl) => tl && tl.kill())
    }
  }, [animationState.canPlay])

  useEffect(() => {
    if (
      !animationState.canPlay ||
      !needsLandingContent ||
      $hasRunModalAnimation.current
    ) {
      return
    }

    $hasRunModalAnimation.current = true
    const tl = animateModalHeaderContent('.tpl-industry')
    return () => tl && tl.kill()
  }, [animationState.canPlay, needsLandingContent])

  if (!industry) return null

  return (
    <ModalLayout
      name={routeName}
      seo={seoData}
      headerBgColor={black}
      subtitle={t('industry:title')}
      title={capitalizeFirstLetter(industryName)}
      uri={props.uri}
      language={language}
      internationalRoute={props.pageContext.internationalRoute}
      landingLink={landingLink}
      isLandingPage={needsLandingContent}
    >
      <section className="hero hero--industry">
        <div className="container">
          {needsLandingContent && (
            <ModalContentHeader
              subtitle={t('industry:title')}
              title={capitalizeFirstLetter(industryName)}
              classNameInfos="col col-md-11 col-md-offset-1 col-lg-offset-1 col-lg-11"
            />
          )}
          <div className="row hero__title">
            <div className="col col-md-offset-1 col-lg-offset-1">
              <div className="row">
                <div className="col col-md-10 col-lg-12">
                  <h2
                    className="ft-500 page__title ft-no-select"
                    aria-label={industry.description.text}
                  >
                    {industry.description.text}
                  </h2>
                </div>
              </div>
              <div className="row mt-5 mt-md-7">
                <div className="col col-md-6 col-lg-7">
                  <div
                    className="ft-default-m-small mb-0 page__desc"
                    dangerouslySetInnerHTML={{
                      __html: industry.sub_description.html,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-md-8 pt-7 pb-b-md-1 pb-7">
        <div className="container">
          {projects && projects.length > 0 && (
            <div className="row">
              <div className="col col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-12 mb-b-md-1 mb-7">
                <p className="h6 mb-5 mb-md-6">{t('industry:title_2')}</p>
                <ListFeaturedProjects
                  projects={featuredProjects}
                  light={true}
                />
              </div>
            </div>
          )}
          {extProjects.length > 0 && (
            <div className="row">
              <div className="col col-md-10 col-md-offset-1 col-lg-12 col-lg-offset-1">
                <p className="h6 mb-4 mb-md-6">
                  {t('industry:title_3', {
                    industry: industryName,
                  })}
                </p>
                <ListProjects projects={extProjects} light={true} />
              </div>
            </div>
          )}
        </div>
      </section>
    </ModalLayout>
  )
}

export const query = graphql`
  query ($uid: String, $language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    industry: prismicIndustry(uid: { eq: $uid }, lang: { eq: $contentLang }) {
      data {
        ...prismicIndustryData
      }
    }
    extProjects: allPrismicProject(filter: { lang: { eq: $contentLang } }) {
      ...prismicProject
    }
  }
`

export default Industry
